import styled from "@emotion/styled";
import { mediaUp } from "../utils/responsive";
import { breakpoints } from "../base/breakpoints";
import { spacing } from "../base/spacing";

// 기본 칼럼 스타일
const baseColStyles = `
    padding: 0 ${spacing[4]};
    
    ${mediaUp("tablet")} {
        padding: 0 ${spacing[10]};
    }
`;

// Col1 ~ Col12 및 ColAuto 생성
export const _Col1 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 8.333333%;
    }
`;

export const _Col2 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 16.666667%;
    }
`;

export const _Col3 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 25%;
    }
`;

export const _Col4 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 33.333333%;
    }
`;

export const _Col6 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 50%;
    }
`;

export const _Col8 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 66.666667%;
    }
`;

export const _Col9 = styled.div`
    ${baseColStyles}
    width: 100%;

    ${mediaUp("tablet")} {
        width: 75%;
    }
`;

export const _Col12 = styled.div`
    ${baseColStyles}
    width: 100%;
`;

export const _ColAuto = styled.div`
    ${baseColStyles}
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
`;

// 사용방법
//         <Col4>Content 1</Col4>
//         <Col4>Content 2</Col4>
//         <Col4>Content 3</Col4>
