'use client';
import { mediaDown } from '@/app/styles';
import styled from '@emotion/styled';
import { ReactElement, useEffect, useCallback } from 'react';

interface Props {
    modals: ReactElement[];
}

const ModalOverlay = ({ modals }: Props) => {
    // 스크롤바 너비 계산을 메모이제이션
    const getScrollbarWidth = useCallback(() => {
        const documentWidth = document.documentElement.clientWidth;
        return Math.abs(window.innerWidth - documentWidth);
    }, []);

    useEffect(() => {
        // RAF를 사용하여 레이아웃 계산을 최적화
        const scrollbarWidth = getScrollbarWidth();

        // class로 처리하여 style 직접 조작 최소화
        if (scrollbarWidth > 0) {
            document.documentElement.classList.add('modal-open');
            document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
        }

        return () => {
            document.documentElement.classList.remove('modal-open');
            document.documentElement.style.removeProperty('--scrollbar-width');
        };
    }, [getScrollbarWidth]);

    return (
        <_inner>
            {modals.map((ele, idx) => (
                <div className="modal-overlay" key={idx}>
                    <_wrapper z={idx}>
                        <_modalWrap z={idx}>{ele}</_modalWrap>
                    </_wrapper>
                </div>
            ))}
        </_inner>
    );
};

export default ModalOverlay;

// styled-components에서 성능 최적화를 위한 속성 추가
const _inner = styled.div`
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    contain: strict; // 성능 최적화
`;

const _wrapper = styled.div<{ z: number }>`
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ z }) => `${100 + z}`};
    background-color: #0000005c;
    display: flex;
    align-items: center;
    justify-content: center;
    contain: content; // 성능 최적화
    will-change: opacity; // 성능 최적화

    ${mediaDown('mobile')} {
        &:has(.login-modal) {
            align-items: flex-end;
            width: 100%;
        }
    }
`;

const _modalWrap = styled.div<{ z: number }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${({ z }) => `${101 + z}`};
    contain: layout style; // 성능 최적화

    ${mediaDown('mobile')} {
        &:has(.login-modal) {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            width: 100%;
            max-height: 90vh;
            margin: 0;
        }
    }
`;
