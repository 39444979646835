import styled from "@emotion/styled";

// 가로 중앙 정렬
export const _FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

// 가로 양쪽 정렬
export const _FlexBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

// 세로 중앙 정렬
export const _FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

// 세로 양쪽 정렬
export const _FlexColumnBetween = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

// 가로 시작점 정렬
export const _FlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

// 가로 끝점 정렬
export const _FlexEnd = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

// 세로 시작점 정렬
export const _FlexColumnStart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

// 세로 끝점 정렬
export const _FlexColumnEnd = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`;

// 가로 균등 정렬
export const _FlexAround = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

// 세로 균등 정렬
export const _FlexColumnAround = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

// 줄바꿈
export const _FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

// 줄바꿈 + 양쪽 정렬
export const _FlexWrapBetween = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

// 기본 Flex 컨테이너 (as로 변환 가능)
export const _Flex = styled.div`
    display: flex;
    align-items: center;
`;
