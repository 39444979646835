// base/spacing.ts
export const spacing = {
    2: "2px",
    4: "4px",
    8: "8px",
    10: "10px",
    12: "12px",
    14: "14px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    30: "30px",
    40: "40px",
    60: "60px",
};
