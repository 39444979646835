// base/zIndex.ts
export const zIndex = {
    // 기본 레이어 (1-99)
    hide: -1, // 요소 숨기기
    auto: "auto", // 자동 쌓임 맥락
    base: 1, // 기본 z-index

    // 플로팅 요소 (100-999)
    floating: 100, // 기본 플로팅 요소
    hover: 200, // 호버 시 표시되는 요소

    // 드롭다운/셀렉트 (1000-1019)
    dropdown: 1000, // 드롭다운 메뉴
    select: 1010, // 셀렉트 박스의 옵션 목록

    // 고정 요소 (1020-1029)
    sticky: 1020, // position: sticky 요소 (헤더, 사이드바 등)

    // 오버레이 요소 (1030-1039)
    fixed: 1030, // position: fixed 요소
    overlay: 1035, // 오버레이 배경

    // 모달 관련 (1040-1049)
    modal: 1040, // 기본 모달
    modalOverlay: 1045, // 모달 배경

    // 팝업 요소 (1050-1059)
    popover: 1050, // 팝오버
    datepicker: 1055, // 데이트피커

    // 알림 요소 (1060-1089)
    tooltip: 1060, // 툴팁
    toast: 1070, // 토스트 메시지
    snackbar: 1080, // 스낵바

    // 최상위 요소 (1090-1099)
    portal: 1090, // 포털
    topmost: 1099, // 최상위에 위치해야 하는 요소

    // 로딩/에러 (1100+)
    loading: 1100, // 로딩 스피너
    error: 1110, // 에러 메시지
    alert: 1120, // 긴급 알림
} as const;
