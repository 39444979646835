// components/buttons/Button.ts
import styled from "@emotion/styled";
import { colors, typography, spacing } from "../base";

// 기본 버튼
export const _Button = styled.button`
    padding: ${spacing[4]} ${spacing[12]};
    border: none;
    border-radius: 4px;
    font-family: ${typography.fontFamily.body};
    font-size: ${typography.fontSize.medium};
    cursor: pointer;
    transition: background-color 0.3s ease;
`;

// 주요 버튼
// 사용: <PrimaryButton>Click me</PrimaryButton>
export const _PrimaryButton = styled(_Button)`
    background-color: ${colors.primary};
    color: ${colors.light};

    &:hover {
        background-color: ${colors.info};
    }
`;

// 보조 버튼
// 사용: <SecondaryButton>Cancel</SecondaryButton>
export const _SecondaryButton = styled(_Button)`
    background-color: ${colors.secondary};
    color: ${colors.light};

    &:hover {
        background-color: ${colors.dark};
    }
`;
