// styles/mixins.ts
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography } from '@/app/styles/base/typography';

// Text Utilities
export const EllipsisText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MultilineEllipsis = (lines: number) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HiddenText = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
`;

// Font Utilities
export const autoFont = css`
    font-family: ${typography.fontFamily.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:not(:lang(ko)) {
        font-family: ${typography.fontFamily.en};
    }
`;

// Layout Utilities
export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const flexColumn = css`
    display: flex;
    flex-direction: column;
`;

// Interactive Utilities
export const clickable = css`
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
`;

// 스크롤바 숨기기
export const hideScrollbar = css`
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

// // 컴포넌트에서 사용
// const Card = styled.div`
//     ${flexCenter}
//     ${clickable}
//     padding: 1rem;
// `;

// const Title = styled.h1`
//     ${autoFont}
//     ${MultilineEllipsis(2)}
// `;

// // 멀티라인 말줄임
// const Description = styled.p`
//     ${MultilineEllipsis(3)}
// `;

// lang 속성을 활용한 예시
// const ProductCard = styled.div`
//   ${autoFont}
// `;

// // 사용
// <ProductCard>
//   <h3 lang="ko">맨투맨</h3>
//   <p lang="en">Classic T-shirt</p>
//   <span lang="en">39,000</span>원
// </ProductCard>
