import styled from "@emotion/styled";
import { colors, typography, spacing, breakpoints } from "../base";

// Form Styles
export const _Form = styled.form`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    @media (max-width: ${breakpoints.mobile}) {
        padding: ${spacing[4]};
    }

    @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        max-width: 600px;
    }
`;

export const _FormGroup = styled.div`
    margin-bottom: ${spacing[10]};
`;

export const _Label = styled.label`
    display: block;
    margin-bottom: ${spacing[2]};
    font-weight: ${typography.fontWeight.bold};
    color: ${colors.dark};
`;

const _inputStyles = `
    width: 100%;
    padding: ${spacing[2]};
    border: 1px solid ${colors.secondary};
    border-radius: 4px;
    font-family: ${typography.fontFamily.body};
    font-size: ${typography.fontSize.medium};
    color: ${colors.dark};
    transition: all 0.3s ease;

    &:focus {
        outline: none;
        border-color: ${colors.primary};
        box-shadow: 0 0 0 2px ${colors.primary}33;
    }
`;

// Input, Textarea, Select Styles
export const _Input = styled.input`
    ${_inputStyles}

    &::placeholder {
        color: ${colors.secondary};
    }
`;

export const _Textarea = styled.textarea`
    ${_inputStyles}
    resize: vertical;
    min-height: 100px;
`;

export const _Select = styled.select`
    ${_inputStyles}
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right ${spacing[4]} center;
`;

// Checkbox & Radio Styles
export const _Checkbox = styled.input`
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-right: ${spacing[2]};
    border: 2px solid ${colors.secondary};
    border-radius: 3px;
    transition: all 0.2s ease;

    &:checked {
        background-color: ${colors.primary};
        border-color: ${colors.primary};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colors.primary}33;
    }
`;

export const _Radio = styled.input`
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-right: ${spacing[2]};
    border: 2px solid ${colors.secondary};
    border-radius: 50%;
    transition: all 0.2s ease;

    &:checked {
        border-color: ${colors.primary};
        background-color: ${colors.primary};
        box-shadow: inset 0 0 0 4px ${colors.light};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colors.primary}33;
    }
`;

// Error & Submit Button Styles
export const _ErrorText = styled.span`
    color: ${colors.danger};
    font-size: ${typography.fontSize.small};
    margin-top: ${spacing[2]};
    display: block;
`;

export const _SubmitButton = styled.button`
    width: 100%;
    padding: ${spacing[4]} ${spacing[10]};
    background-color: ${colors.primary};
    color: ${colors.light};
    border: none;
    border-radius: 4px;
    font-size: ${typography.fontSize.medium};
    font-weight: ${typography.fontWeight.bold};
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${colors.info};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colors.primary}33;
    }

    &:disabled {
        background-color: ${colors.secondary};
        cursor: not-allowed;
    }
`;
