'use client';

import { ReactElement, ReactNode, createContext, useMemo, useState } from 'react';
import ModalOverlay from './ModalOverlay';

interface Props {
    children: ReactNode;
}

type Modals = { modal: ReactElement[] };

const ModalContextState = createContext<Modals>({
    modal: [],
});

export const ModalContextDispatch = createContext({
    addModal: (modal: ReactElement) => {},
    clearModal: (count: number | undefined = 1) => {},
});

const ModalContext = ({ children }: Props) => {
    const [modal, setModal] = useState<ReactElement[]>([]);

    const addModal = (modal: ReactElement) => {
        setModal((prev) => [...prev, modal]);
    };

    const clearModal = (count: number | undefined = 1) => {
        const calcModals = (prev: ReactElement[]) => {
            const length = prev.length - count;
            const slice = prev.slice(0, length);
            return slice;
        };

        setModal((prev) => calcModals(prev));
    };

    const dispatch = useMemo(() => ({ addModal, clearModal }), []);

    // console.log('모달 컨텍스트 렌더');

    return (
        <ModalContextDispatch.Provider value={dispatch}>
            {modal.length ? <ModalOverlay modals={modal} /> : <></>}
            {children}
        </ModalContextDispatch.Provider>
    );
};

export default ModalContext;
