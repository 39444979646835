// base/breakpoints.ts
export const breakpoints = {
    smallMobile: '375px',
    mobile: '768px',
    tablet: '1024px',
    desktop: '1256px',
    largeDesktop: '1570px',
} as const;

export type BreakpointKey = keyof typeof breakpoints;
