import { breakpoints, BreakpointKey } from "../base/breakpoints";

// 미디어 쿼리 생성 함수
export const createMediaQuery = (minMax: "min" | "max") => (breakpoint: BreakpointKey) => {
    const breakpointValue = breakpoints[breakpoint];
    if (!breakpointValue) {
        console.warn(`Warning: Undefined breakpoint '${breakpoint}'`);
        return "";
    }
    return `@media (${minMax}-width: ${breakpointValue})`;
};

// 기본 미디어 쿼리
export const mediaUp = createMediaQuery("min");
export const mediaDown = createMediaQuery("max");

// 범위 미디어 쿼리
export const mediaBetween = (min: BreakpointKey, max: BreakpointKey) => {
    const minValue = breakpoints[min];
    const maxValue = breakpoints[max];
    if (!minValue || !maxValue) {
        console.warn(`Warning: Undefined breakpoints '${min}' or '${max}'`);
        return "";
    }
    return `@media (min-width: ${minValue}) and (max-width: ${maxValue})`;
};

// styled-components에서 사용할 수 있는 미디어 쿼리 헬퍼
export const media = {
    up: (breakpoint: BreakpointKey) => `@media (min-width: ${breakpoints[breakpoint]})`,
    down: (breakpoint: BreakpointKey) => `@media (max-width: ${breakpoints[breakpoint]})`,
    between: (min: BreakpointKey, max: BreakpointKey) => `@media (min-width: ${breakpoints[min]}) and (max-width: ${breakpoints[max]})`,
};

// // 1. 기본 사용
// import styled from "@emotion/styled";
// import { media } from "../utils/responsive";

// export const ResponsiveContainer = styled.div`
//     padding: 1rem;

//     ${media.up("tablet")} {
//         padding: 2rem;
//     }

//     ${media.up("desktop")} {
//         padding: 3rem;
//         max-width: 1200px;
//         margin: 0 auto;
//     }
// `;

// // 2. 복잡한 반응형 스타일
// export const FlexGrid = styled.div`
//     display: grid;
//     grid-template-columns: 1fr;
//     gap: 1rem;

//     ${media.up("tablet")} {
//         grid-template-columns: repeat(2, 1fr);
//     }

//     ${media.up("desktop")} {
//         grid-template-columns: repeat(4, 1fr);
//     }
// `;

// // 3. 범위 지정 스타일
// export const TabletOnlyElement = styled.div`
//     display: none;

//     ${media.between("tablet", "desktop")} {
//         display: block;
//     }
// `;

// // 4. 모바일 우선 디자인
// export const Card = styled.div`
//     width: 100%;
//     padding: 1rem;

//     ${media.up("tablet")} {
//         width: 50%;
//     }

//     ${media.up("desktop")} {
//         width: 33.333%;
//     }
// `;

// // 5. 실제 컴포넌트 예시
// const ResponsiveLayout = styled.div`
//     // 모바일 기본 스타일
//     padding: ${props => props.theme.spacing.sm};

//     // 태블릿
//     ${media.up("tablet")} {
//         padding: ${props => props.theme.spacing.md};
//         display: flex;
//         flex-wrap: wrap;
//     }

//     // 데스크탑
//     ${media.up("desktop")} {
//         padding: ${props => props.theme.spacing.lg};
//         max-width: ${props => props.theme.breakpoints.desktop};
//         margin: 0 auto;
//     }
// `;
