// base/typography.ts
export const typography = {
    fontFamily: {
        body: 'var(--font-pretendard)',
        en: 'var(--font-roboto)',
    },
    fontSize: {
        tiny: '12px',
        small: '14px',
        base: '16px',
        medium: '20px',
        large: '24px',
        xlarge: '28px',
        xxlarge: '32px',
        plus2: '40px',
        plus3: '48px',
        plus4: '56px',
        plus5: '64px',
        plus6: '84px',
        plus7: '104px',
        plus8: '120px',
        plus9: '152px',
    },
    fontWeight: {
        thin: 100, // Pretendard & Roboto Thin
        extraLight: 200, // Pretendard only
        light: 300, // Pretendard & Roboto Light
        normal: 400, // Pretendard & Roboto Regular
        medium: 500, // Pretendard & Roboto Medium
        semiBold: 600, // Pretendard only
        bold: 700, // Pretendard & Roboto Bold
        extraBold: 800, // Pretendard only
        black: 900, // Pretendard & Roboto Black
    },
    lineHeight: {
        xs: 1.1,
        tight: 1.2,
        normal: 1.5,
        loose: 1.8,
    },
} as const;
