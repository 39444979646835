import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';

// body 바로 아래 div 태그
export const _commonInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

// _commonInner 아래 main 태그
export const _commonMain = styled.main`
    flex: 1;
    display: flex;
`;

// page
export const _commonContainer = styled.div`
    width: ${breakpoints.desktop};
    max-width: 100%;
    margin: 0 auto;
    position: relative;

    ${mediaDown('desktop')} {
        padding: 0 20px 0;
    }

    ${mediaDown('tablet')} {
    }

    ${mediaDown('mobile')} {
        padding: 0 16px 0;
    }
`;

export const _commonContainerPd0 = styled.div`
    width: ${breakpoints.desktop};
    max-width: 100%;
    margin: 0 auto;
    position: relative;
`;

// 상품 없습니다 텍스트 센터 공통
export const _textCenter = styled.p`
    position: absolute;
    width: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

// 메인 타이틀
export const _MainTitle = styled.p`
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 24px;

    @media screen and (max-width: 1536px) {
        padding: 0 16px;
    }
    @media screen and (max-width: 1026px) {
        display: none;
    }
`;

export const _inner = styled.div`
    width: 100%;
    position: relative;
`;

// 좋아요 포함한 상품의 정보
export const _info = styled.div`
    padding: 12px 8px 24px 12px;

    ${mediaDown('tablet')} {
        padding: 8px 4px 12px 8px;
    }
`;

export const _infoContent = styled.div`
    text-align: left;
`;

export const _like = styled.button`
    display: flex;
    align-items: center;
    gap: 6px;
    background: none;
    border: none;
    /* padding: 4px; */
    cursor: pointer;
    margin: -4px;
    margin-bottom: 12px;

    > span {
        font-size: 15px;
        color: #787878;
        margin-top: 4px;

        ${mediaDown('tablet')} {
            font-size: 13px;
        }
    }

    ${mediaDown('tablet')} {
        font-size: 13px;
        gap: 4px;

        > svg {
            width: 24px;
            height: 24px;
        }
    }
`;

export const _brand = styled.div`
    font-size: 15px;
    color: #787878;
    font-weight: 400;
    margin-bottom: 6px;

    ${mediaDown('tablet')} {
        font-size: 13px;
        margin-bottom: 4px;
    }
`;

export const _name = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;

    ${mediaDown('tablet')} {
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 500;
    }
`;

export const _priceInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: auto;
`;

export const _sale = styled.span`
    color: #4d4dff;
    font-size: 16px;
    font-weight: 500;

    ${mediaDown('tablet')} {
        font-size: 13px;
        font-weight: 500;
    }
`;

export const _price = styled.span`
    font-size: 16px;
    font-weight: 600;

    ${mediaDown('tablet')} {
        font-size: 13px;
        font-weight: 500;
    }
`;
export const _paginationWrapper = styled.div`
    padding: 64px 0 64px;

    ${mediaDown('tablet')} {
        padding: 24px 0 24px;
    }
`;
