// base/colors.ts
export const colors = {
    // 주요 색상
    primary: '#4D4DFF',
    red: '#E91237',
    gold: '#F5A811',
    mint: '#00C496',

    // 브랜드 특수 색상
    black: '#000',
    white: '#fff',
    accent: '#E91237',
    highlight: '#4D4DFF',
    textDarker: {
        50: '#E0E0E0', // 선
        100: '#A0A0A0', // 메인 회색 컬러
        200: '#787878', // 메인 글자 컬러
    },
    bgDarker: {
        50: '#F4F4F4', // 박스 백그라운드
        100: '#D9D9D9',
        200: '#C8C8C8',
        300: '#545454',
        400: '#404040',
    },

    // 기본 색상
    text: '#333333',
    background: '#ffffff',

    // 특수 색상
    black1: '#202020',
    red1: '#E91237',
    blue1: '#4D4DFF',
    secondary: '#6c757d',
    success: '#28a745',
    danger: '#dc3545',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#343a40',

    // 그레이 스케일
    gray1: '#A0A0A0',
    gray2: '#F4F4F4',
    gray3: '#D0D0D0',
    gray4: '#787878',
    gray5: '#E0E0E0',
    gray6: '#404040',
    gray7: '#D9D9D9',

    // 라이트 그레이 스케일
    lightGray50: '#F6F6F8',
    lightGray100: '#E7E7ED',
    lightGray200: '#D6D8DD',
    lightGray300: '#BBBEC4',
    lightGray400: '#9A9EA7',

    // 다크 그레이 스케일
    darkGray500: '#78787D',
    darkGray600: '#65656A',
    darkGray700: '#47474C',
    darkGray800: '#333333',
    darkGray900: '#151516',
} as const;
