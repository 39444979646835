// components/navigation/Navigation.ts
import styled from "@emotion/styled";
import { colors, typography, spacing } from "../base";

export const Navbar = styled.nav`
    background-color: ${colors.primary};
    padding: ${spacing[2]} ${spacing[10]};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NavList = styled.ul`
    list-style-type: none;
    display: flex;
    gap: ${spacing[10]};
`;

export const NavItem = styled.a`
    color: ${colors.light};
    font-size: ${typography.fontSize.medium};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const ActiveNavItem = styled(NavItem)`
    font-weight: ${typography.fontWeight.bold};
`;

export const Dropdown = styled.div`
    position: relative;

    &:hover > ul {
        display: block;
    }
`;

export const DropdownContent = styled.ul`
    display: none;
    position: absolute;
    background-color: ${colors.light};
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: ${spacing[4]} 0;
    list-style: none;
`;

export const DropdownItem = styled.a`
    color: ${colors.dark};
    padding: ${spacing[2]} ${spacing[4]};
    display: block;

    &:hover {
        background-color: ${colors.secondary};
        color: ${colors.light};
    }
`;
// Navigation 사용 예시
// const NavigationExample = () => {
//     return (
//         <Navbar>
//             <NavList>
//                 <li><NavItem href="/">홈</NavItem></li>
//                 <li><ActiveNavItem href="/about">소개</ActiveNavItem></li>
//                 <li>
//                     <Dropdown>
//                         <NavItem href="#">더보기</NavItem>
//                         <DropdownContent>
//                             <li><DropdownItem href="/service">서비스</DropdownItem></li>
//                             <li><DropdownItem href="/contact">문의</DropdownItem></li>
//                         </DropdownContent>
//                     </Dropdown>
//                 </li>
//             </NavList>
//         </Navbar>
//     );
// };
