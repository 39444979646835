import { breakpoints, colors, typography, spacing, zIndex } from './base';

export const theme = {
    colors,
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSize,
    fontWeight: typography.fontWeight,
    lineHeight: typography.lineHeight,
    spacing,
    breakpoints,
    zIndex,
    borderRadius: {
        4: '4px',
        8: '8px',
        12: '12px',
        16: '16px',
        circle: '50%',
        pill: '9999px',
    },
    shadows: {
        sm: '0 2px 4px rgba(0, 0, 0, 0.1)',
        md: '0 4px 6px rgba(0, 0, 0, 0.1)',
        lg: '0 8px 16px rgba(0, 0, 0, 0.1)',
    },
    transitions: {
        fast: '0.2s ease',
        normal: '0.3s ease',
        slow: '0.5s ease',
    },
    display: {
        flexRow: `
            display: flex;
            justify-content: center;
            align-items: center;
        `,
        flexRowBetween: `
            display: flex;
            justify-content: space-between;
            align-items: center;
        `,
        flexColumn: `
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        `,
        flexColumnBetween: `
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        `,
    },
} as const;

export type Theme = typeof theme;
